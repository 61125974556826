import React, { useEffect, useContext } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { GlobalDispatchContext } from '../context/globalContextProvider';

function ThankYou() {
	const dispatch = useContext(GlobalDispatchContext);
	useEffect(() => {
		dispatch({ type: 'DESTROY_CART' });
	}, [dispatch]);

	return (
		<Layout>
			<SEO title="Home" />
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'content',
					padding: '9rem 0',
					flexDirection: 'column',
				}}
			>
				<h3>ขอบคุณสำหรับการสั่งซื้อของท่าน</h3>
				<h4 style={{ fontWeight: '400', marginTop: '1rem' }}>
					ท่านจะได้รับอีเมลล์ยืนยันสินค้าทางอีเมลล์ในอีกซักครู่
				</h4>
				<div>ตรวจสอบสถานะพัสดุ</div>
				<div>
					หลังจากการจัดส่งสินค้าเสร็จสิ้น
					กลุ่มมาลัยจะทำการส่งเลขติดตามพัสดุให้ท่านผ่านทางอีเมลล์ หรือไลน์
					ตามที่ท่านได้เลือกตอนสั่งซื้อสินค้า
				</div>
			</div>
		</Layout>
	);
}

export default ThankYou;
